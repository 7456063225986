<template>
  <div class="edu-frame">
    <edu-nav-bar title="活动心得" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
    <div class="content-frame">
      <div @click="writeExperience(null)" style="display: flex;justify-content: center;align-items: center;flex-direction: column;font-size: 14px;color: white;font-weight: bold;width: 50px;height: 50px;background-color: #39a9ed;position: absolute;right: 20px;bottom: 20px;border-radius: 50%;">
        <div>填写</div>
        <div>心得</div>
      </div>
      <van-list
          class="activity-list"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div v-for="(item,index) in list" :key="index" class="class-list" style="background-color: white;">
          <div class="class-item" style="flex-direction: column;display: flex;margin-left: auto;margin-right: auto;width: calc(98% - 20px);">
            <div class="class-detail">
              <div class="class-detail-title">活动名称：{{ item.activityName }}</div>
              <div class="class-detail-more">开展地点：{{ item.commentAddress }}</div>
              <div class="class-detail-more">
                <div class="class-detail-row">
                  <div class="class-detail-row-title">家长参加人数：{{ item.commentParentCount }}</div>
                  <div class="class-detail-row-title">家长参加人数：{{ item.commentChildCount }}</div>
                </div>
              </div>
            </div>

            <div style="display: flex;justify-content: flex-end;">
              <van-button style="background-color: orange;color: white;font-weight: bold;" square size="small" type="default" @click="writeExperience(item)">查看心得</van-button>
            </div>




          </div>
        </div>
        <hr class="class-split"/>
      </van-list>

    </div>

  </div>
</template>
<script>
/*Checkbox*/
import {Button, List} from "vant";
import EduNavBar from "@/components/VolunteerEduNavBar";
import ActivityApi from "@/api/ActivityApi";
import Tools from "@/api/Tools";
import EduConstants from '@/api/EduConstants';
import XEUtils from 'xe-utils'

export default {
  components: {
    EduNavBar,
    VanButton: Button,
    VanList: List
    /*VanCheckbox: Checkbox*/
  },
  filters: {
    filterDate(date) {
      return XEUtils.toDateString(date, 'yyyy-MM-dd HH:mm:ss')
    }
  },
  data() {
    return {
      cust: Tools.getCurCust(),
      LOGINTYPE_CHILD: EduConstants.LOGINTYPE_CHILD,
      LOGINTYPE_OLD: EduConstants.LOGINTYPE_OLD,
      show: false,
      list: [],
      payTotal: 0,
      reportData: '',
      loading: false,
      finished: false,
      pageInfo: { page: 1, pageSize: 15, condition: {} },
    }
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageInfo.condition.custId = this.cust.custId
      ActivityApi.getActivityVolunteersForPage(this.pageInfo).then(response => {
        this.loading = false
        console.log(response)
        for(var i = 0; i < response.res.list.length; i++) {
          this.list.push(response.res.list[i])
        }
        if (response.res.isLastPage == 1) {
          this.finished = true
        } else {
          this.pageInfo.page = this.pageInfo.page + 1
        }
      }).catch(() => { this.loading = false;this.finished = true })
    },
    writeExperience(item) {
      this.$router.push({ name: 'vhdxdedit',query: { commentId: item == null ? null : item.commentId } })
    },
    cancelActivity(item) {
      ActivityApi.cancelBuyActivity({ commentId: item.commentId }).then(response => {
        this.$dialog.alert({message: response.msg})
        if (response.code == 100) {
          this.onLoad()
        }
      })
    }
  },
  mounted() {
    // this.getAlreadyBaoduClasses()
  }
}
</script>
<style scoped>
.class-list {
  width: 95%;
  border-radius: 8px;
  border: 1px solid lightgrey;
  box-shadow: 1px 2px 2px #ddd;
  margin: 10px auto;
  background-color: #cde7f7;
}

.title-tips {
  margin-left: 2%;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  color: black;
}

.class-split {
  width: 95%;
  height: 1px;
  border-top: 2px dotted lightgrey;
}
.pop-btn {
  position: fixed;
  right: 0;
  bottom: 50px;
  background-color: #1989fa;
  padding: 3px 8px;
  font-size: 14px;
  color: white;
  border-left: 2px solid lightblue;
  border-top: 2px solid lightblue;
  border-bottom: 2px solid lightblue;
  z-index: 999;
}

.content-frame {
  height: calc(100vh - 46px);
}

.activity-list {
  width: 100%;
  overflow: auto;
}
</style>
